$ = require('jquery');

$(function () {

  $("#guest_address_attributes_postal_code").on('input', function () {
    let count = $(this).val().length;
    if (count === 7) {
      let postal_code = $(this).val();
      $.ajax({
        type: 'GET',
        url: 'https://madefor.github.io/postal-code-api/api/v1/' + postal_code.slice(0, 3) + '/' + postal_code.slice(3) + '.json'
      }).done(function (res) {
        if (res.data.length > 1) {
          $('#selectAddress > option').remove();
          const options = []
          $.each(res.data, function (index, value) {
            let ja = value.ja
            options.push({text: ja.prefecture + ja.address1 + ja.address2 + ja.address3 + ja.address4, value: index})
          })
          var select = $('#selectAddress');
          var keys = Object.keys(options);
          keys.forEach(function (key) {
            var content = this[key];
            var option = $('<option>')
              .text(content['text'])
              .val(content['value']);
            select.append(option);
          }, options);
          $('#selectAddressModal').modal('show')
          $('#closeSelectAddressModal').on('click', function () {
            let selected = $('#selectAddress option:selected').val();
            let ja = res.data[selected].ja;
            $("#guest_address_attributes_prefecture").val(ja.prefecture);
            $("#guest_address_attributes_city").val(ja.address1);
            $("#guest_address_attributes_street").val(ja.address2 + ja.address3 + ja.address4);
            $('#js-guest-auto-address').val(ja.prefecture + ja.address1 + ja.address2 + ja.address3 + ja.address4)

            let en = res.data[selected].en;
            $("#guest_address_attributes_prefecture_en").val(en.prefecture);
            $("#guest_address_attributes_city_en").val(en.address1);
            $("#guest_address_attributes_street_en").val(en.address2 + en.address3 + en.address4);

            $('#selectAddressModal').modal('hide')
          });
        } else {
          let ja = res.data[0].ja;
          $("#guest_address_attributes_prefecture").val(ja.prefecture);
          $("#guest_address_attributes_city").val(ja.address1);
          $("#guest_address_attributes_street").val(ja.address2 + ja.address3 + ja.address4);
          $('#js-guest-auto-address').val(ja.prefecture + ja.address1 + ja.address2 + ja.address3 + ja.address4)

          let en = res.data[0].en;
          $("#guest_address_attributes_prefecture_en").val(en.prefecture);
          $("#guest_address_attributes_city_en").val(en.address1);
          $("#guest_address_attributes_street_en").val(en.address2 + en.address3 + en.address4);
        }

      })
    }
  });
});


import $ from "jquery";

$(function() {

  $(".CircularProgress").each(function() {

    var value = $(this).attr('data-value');
    var left = $(this).find('.CircularProgress__left--bar');
    var right = $(this).find('.CircularProgress__right--bar');

    if (value > 0) {
      if (value <= 50) {
        right.css('transform', 'rotate(' + percentageToDegrees(value) + 'deg)')
      } else {
        right.css('transform', 'rotate(180deg)')
        left.css('transform', 'rotate(' + percentageToDegrees(value - 50) + 'deg)')
      }
    }

  })

  function percentageToDegrees(percentage) {
    return percentage / 100 * 360
  }

  var rating = $('.js-rating');

  rating.each(function(index, el) {
    let score = $(el).data('score')
    $(el).raty({
      starType: 'i',
      starOff: 'far fa-star text-warning',
      starOn: 'fas fa-star text-warning',
      readOnly: true,
      score: score
    })
  })

  var totalRating = $('.js-total-rating');

  totalRating.raty({
    starType: 'i',
    starOff: 'far fa-star text-warning',
    starOn: 'fas fa-star text-warning',
    starHalf: 'fa fa-star-half-o text-warning',
    half: true,
    readOnly: true,
    score: $(this).data('score')
});

});

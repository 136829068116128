import $ from 'jquery';
import 'slick-carousel';

$(document).ready(function() {
  $('.js-header-slider').slick({
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
  });

  var rating = $('.js-rating-home');

  rating.each(function(index, el) {
    let score = $(el).data('score')
    $(el).raty({
      starType: 'i',
      starOff: 'far fa-star text-warning',
      starOn: 'fas fa-star text-warning',
      starHalf: 'fa fa-star-half-o text-warning',
      half: true,
      readOnly: true,
      score: score
    })
  })
})
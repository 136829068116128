import $ from "jquery";

$(document).ready(function() {
  var rating = $('.js-rating-review')

  rating.raty({
    starOff: 'https://res.cloudinary.com/dodcnnhpa/image/upload/v1609382944/star-off.png',
    starOn: 'https://res.cloudinary.com/dodcnnhpa/image/upload/v1609382947/star-on.png',
    scoreName: 'review[rating]'
  });
})